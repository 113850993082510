import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  green: {
    color: theme.palette.primary.light,
  },
  blue: {
    color: "#1F3598",
  },
  card: {
    boxShadow: "1px 4px 20px 5px rgb(128 128 132 / 11%)",
    padding: theme.spacing(2),
    borderRadius: 5,
    marginBottom: theme.spacing(2),
  },
  cardDate: {
    backgroundColor: "#CED6E9",
    color: "#1F3598",
    fontWeight: "bold",
    marginTop: theme.spacing(2)
  },
  cDR: {
    marginLeft: 12,
  },
  cardTitle: {
    margin: 0,
    fontWeight: "bold",
  },
  title: {
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: theme.spacing(10),
  },
  cardContentR: {
    padding: 0,
    margin: 0,
    textAlign: "left",
    fontSize: "1em",
  },
  withMarginTop: {
    marginTop: theme.spacing(2),
  },
  year: {
    fontSize: "1.7em"
  }
}));

const TimelineMobile = ({
  year,
  date,
  title,
  children,
  green,
  withMarginTop,
}) => {
  const classes = useStyles();
  return (
    <Grid
      item
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className={clsx(classes.card, { [classes.withMarginTop]: withMarginTop })}
    >
      <Grid item xs={1} style={{ textAlign: "left" }}>
        <Typography
          variant="h1"
          className={clsx(classes.year, { [classes.green]: green, [classes.blue]: !green })}
        >
          {year}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Grid container direction="column" alignItems="flex-start" spacing={3}>
          <Grid
            item
            className={clsx(classes.cardDate, classes.cDR)}
            style={{ padding: 2 }}
          >
            {date}
          </Grid>
          <Grid item>
            <Typography variant="h5" className={classes.cardTitle}>
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" className={classes.cardContentR}>
              {children}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TimelineMobile;
