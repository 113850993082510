import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import formatTitle from "../../components/common/formatTitle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TimelineMobileCard from "./TimelineMobileCard";

const useStyles = makeStyles((theme) => ({
  paddingRegularMobile: {
    padding: theme.layout.paddingRegularMobile,
  },
  title: {
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: theme.spacing(10),
  },
}));

const TimelineMobile = (props) => {
  const classes = useStyles();
  if (!props.datas) return null;
  const { title, items } = props.datas;
  return (
    <Grid
      item
      container
      direction="column"
      alignItems="stretch"
      className={clsx(classes.paddingRegularMobile)}
    >
      <Grid item>
        <Typography variant="h1" className={classes.title}>
          {formatTitle(title)}
        </Typography>
      </Grid>
      <TimelineMobileCard
        year="2017"
        date={items[0].date}
        title={items[0].title}
        green
      >
        {items[0].content}
      </TimelineMobileCard>
      <TimelineMobileCard
        year="2018"
        date={items[1].date}
        title={items[1].title}
        withMarginTop
      >
        {items[1].content}
      </TimelineMobileCard>
      <TimelineMobileCard
        year="2019"
        date={items[2].date}
        title={items[2].title}
        green
        withMarginTop
      >
        {items[2].content}
      </TimelineMobileCard>
      <TimelineMobileCard
        year="2020"
        date={items[3].date}
        title={items[3].title}
        withMarginTop
      >
        {items[3].content}
      </TimelineMobileCard>
      <TimelineMobileCard
        year="2020"
        date={items[4].date}
        title={items[4].title}
        green
        withMarginTop
      >
        {items[4].content}
      </TimelineMobileCard>
    </Grid>
  );
};

export default TimelineMobile;
